<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
import { Modal } from "bootstrap"
var state = store.state;
export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        PageHeader,

    },
    data() {
        return {
            hr_leaves: [],
            leaglConditions: [
                { name: "Yes", value: 1 },
                { name: "No", value: 0 },
            ],
            subscriptionPackages: [],
            subscriptionPackagesFeatures: [],
            subscriptionFeatures: [],
            page: 1,
            limit: 20,
            tot_pages: 0,
            page_subscription_packages_features: 1,
            limit_subscription_packages_features: 20,
            tot_pages_subscription_packages_features: 0,
            SubscriptionPackagesSearchMode: false,
            subscriptionPackagesFeaturesSearchMode: false,
            searchModel: "",
            subscriptionPackagesFeaturesSearchModal: "",
            state: state,
            subscriptionPackagesFeaturesModalInstance: null

        };
    },
    methods: {

        openSubscriptionPackagesFeaturesModal() {
            var modal = new Modal("#subscriptionPackagesFeaturesModal")
            this.subscriptionPackagesFeaturesModalInstance = modal
            this.subscriptionPackagesFeaturesModalInstance.show()
        },



        getSubscriptionPackagesFeaturesCheckbox(id) {
            return new Promise((resolve) => {

                this.http.get(`subscription-packages-features/checkbox/${id}`, null, null, true).then((res) => {
                    if (res) {

                        console.log("log :", res.data)
                        this.subscriptionPackagesFeatures = res.data
                        this.subscriptionPackagesFeatures = this.subscriptionPackagesFeatures.map((e) => ({ check_id: e.subscription_feature_id, label: this.$i18n.locale == "en" ? e.subscription_feature?.name_en : e.subscription_feature?.name, checked : true }))
                      console.log("log 2:", this.subscriptionPackagesFeatures)


                      resolve(this.subscriptionPackagesFeatures)
                    }
                    else {
                      resolve(this.subscriptionPackagesFeatures)

                    }
            })



           })
        },

        addSubscriptionPackagesFeature() {

            var data = {
                title: "popups.add",
                inputs: [
                    {
                        label: "Roles",
                        type: "checkbox",
                        options: this.subscriptionFeatures,
                        model: "subscription_feature_id"

                    },

                ],
                buttons: [
                    {
                        text: "popups.add",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            // this.http.post("subscription-packages", obj, null, true).then(() => {
                            //     this.get(this.page);
                            // });
                            console.log("result :", obj)
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);
        },
        getSubscriptionFeatures() {
            return new Promise((resolve) => {

                this.http.get(`subscription-features`, null, null, true).then((res) => {
                    this.subscriptionFeatures = res.data
                    this.subscriptionFeatures = this.subscriptionFeatures.map((e) => ({ check_id: e.id, label: this.$i18n.locale == "en" ? e.name_en: e.name, }))
                    console.log("log 1 :", this.subscriptionFeatures)

                    resolve(this.subscriptionFeatures)

                })
            })
        },

        addSubscriptionPackage() {
            var data = {
                title: "popups.add",
                inputs: [
                    { model: "name", type: "text", label: "subscription_packages.name" },
                    { model: "name_en", type: "text", label: "subscription_packages.name_en" },
                    { model: "descr", type: "textarea", label: "subscription_packages.descr" },
                    { model: "descr_en", type: "textarea", label: "subscription_packages.descr_en" },
                    { model: "monthly_price", type: "number", label: "subscription_packages.monthly_price" },
                    { model: "annual_price", type: "number", label: "subscription_packages.annual_price" },
                    { model: "subscription_feature", type: "checkbox", label: "Roles", options: this.subscriptionFeatures },
                ],
                buttons: [
                    {
                        text: "popups.add",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.post("subscription-packages", obj, null, true).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);
        },
         async editSubscriptionPackage(app) {

             let newFeatures = await this.getSubscriptionPackagesFeaturesCheckbox(app.id)
             let originalFeatures = await this.getSubscriptionFeatures()

            console.log("app :", app)


            console.log("id :", app.id)
            newFeatures.forEach(hubFeature => {
                let originalFeature = originalFeatures.find(obj => obj.check_id === hubFeature.check_id);
                if (originalFeature) {
                    originalFeature.checked = true
                    console.log("original feature :",originalFeature )
                }
            });
            console.log("new array :", newFeatures)
            console.log("orginal array :", this.subscriptionFeatures)
            var data = {
                title: "popups.edit",
                inputs: [


                    { model: "name", type: "text", label: "subscription_packages.name", value: app.name },
                    { model: "name_en", type: "text", label: "subscription_packages.name_en", value: app.name_en },
                    { model: "descr", type: "textarea", label: "subscription_packages.descr", value: app.descr },
                    { model: "descr_en", type: "textarea", label: "subscription_packages.descr_en", value: app.descr_en },
                    { model: "monthly_price", type: "number", label: "subscription_packages.monthly_price", value: app.monthly_price },
                    { model: "annual_price", type: "number", label: "subscription_packages.annual_price", value: app.annual_price },
                    { model: "subscription_features", type: "checkbox", label: "Roles", options: originalFeatures },
                ],
                buttons: [
                    {
                        text: "popups.edit",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.put("subscription-packages", app.id, obj).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");

                            console.log("log 1 :", this.subscriptionFeatures)
                        },
                    },
                ],
            };

            this.popup.modal(data);



        },
        search() {
            this.SubscriptionPackagesSearchMode = true;
            this.tot_pages = 0;
            this.http.post("subscription-packages/search", {
                search: this.searchModel,
                limit: this.limit,
                page: this.page,
                col: "name",
            }, null, true)
                .then((res) => {
                    this.subscriptionPackages = res.data;
                });
        },
        cancelappsearchMode()
         {
            this.searchModel = "";
            this.SubscriptionPackagesSearchMode = false;
            this.get(this.page);
        },

        getSubscriptionPackages() {
            this.http.get("subscription-packages", null, null, true).then((res) => {
                this.subscriptionPackages = res.data;
            });
        },
        deleteSubscriptionPackages(app) {
            var data = {
                title: "popups.delete",
                msg: "popups.delmessage",
            };
            this.popup.confirm(data).then((resp) => {
                if (resp) {
                    this.http.delete("subscription-packages", app.id).then(() => {
                        this.get(this.page);
                    });
                }
            });
        },

        get(page) {
            console.log(page);
            this.http.post("subscription-packages/paginate", {
                limit: this.limit,
                page: page,
            }, null, true)
                .then((res) => {
                    this.page = page;
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.subscriptionPackages = res.data;
                });
        },

    },
    mounted() {
        this.get(1);
        this.getSubscriptionFeatures()
    },
};
</script>

<template>
        <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :title="$t('menu.menuitems.subscriptions_management.subItems.packages')" />
        <div class="row mb-4">
            <div class="col-6">
                <div class="search-box chat-search-box w-50">
                    <div class="position-relative">
                        <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')"
                            v-model="searchModel" style="background-color: #2a3042 !important" />
                        <i class="bx bx-search-alt search-icon text-light"></i>
                    </div>
                </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
                <button class="btn-close text-light mb-4" v-if="SubscriptionPackagesSearchMode"
                    @click="cancelappsearchMode()"></button>
                <button type="button" @click="addSubscriptionPackage()" class="btn btn-light float-end mb-4">
                    <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                </button>
            </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
                <tr class="text-light text-center" style="background-color: #2a3042 !important">
                    <th scope="col">{{ $t("subscription_packages.id") }}</th>
                    <th scope="col">{{ $t("subscription_packages.name") }}</th>
                    <th scope="col">{{ $t("subscription_packages.name_en") }}</th>
                    <th scope="col">{{ $t("subscription_packages.descr") }}</th>
                    <th scope="col">{{ $t("subscription_packages.descr_en") }}</th>
                    <th scope="col">{{ $t("subscription_packages.monthly_price") }}</th>
                    <th scope="col">{{ $t("subscription_packages.annual_price") }}</th>
                    <th scope="col">{{ $t("subscription_packages.created") }}</th>
                    <th scope="col">{{ $t("subscription_packages.updated") }}</th>
                    <th scope="col">{{ $t("subscription_packages.operations") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="app in subscriptionPackages" :key="app" class="text-center">
                    <td>{{ app?.id }}</td>
                    <td>{{ app?.name }}</td>
                    <td>{{ app?.name_en }}</td>
                    <td>{{ app?.descr }}</td>
                    <td>{{ app?.descr_en }}</td>
                    <td>{{ app?.monthly_price }}</td>
                    <td>{{ app?.annual_price }}</td>
                    <td>{{ new Date(app?.created).toLocaleString('en-GB') }}</td>
                    <td>{{ new Date(app?.updated).toLocaleString('en-GB') }}</td>

                    <td class="d-flex">
                        <a class="btn btn-primary mx-1" @click="editSubscriptionPackage(app)" href="javascript: void(0);"
                            role="button">{{ $t("popups.edit") }}</a>
                        <!-- <a class="btn btn-danger" href="javascript: void(0);" role="button"
                            @click="deleteSubscriptionPackages(app)">{{ $t("popups.delete") }}</a> -->
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages == 20">
            <!--   Apps  Pagination     -->
            <ul class="pagination pagination-rounded justify-content-center mb-2">
                <li class="page-item" :class="{ disabled: page == 1 }">
                    <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
                        <i class="mdi mdi-chevron-left"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                    <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
                        p
                    }}</a>
                </li>

                <li class="page-item" :class="{ disabled: page == total_pages }">
                    <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
                        <i class="mdi mdi-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>


        <!-- Button trigger modal -->


        <!-- Modal -->




        <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
